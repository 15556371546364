<template>
  <div>
    <calendar :events="events" @select="select" @eventClick="eventClick" :payload="payload" ref="calendar"
    class="ma-1" @refresh="getAllEvents(start, end)" :key="calendarKey"></calendar>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="pa-3 pa-lg-3 primary white--text">
          <p class="text-capitalize font-weight-bold ma-0 text-body-1 text-lg-h6"> New </p>
          <v-spacer></v-spacer>
          <template v-if="$_isSameDate(modelObj.start, modelObj.end)">
            <v-chip outlined color="white"> {{ $_formatDate(modelObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY') }} </v-chip>
          </template>
          <template v-else>
            <v-chip outlined color="white" :x-small="xsOnly" class="py-3 py-lg-0"> {{ $_formatDate(modelObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY') }} </v-chip>
            <span class="px-1"> ~ </span>
            <v-chip outlined color="white" :x-small="xsOnly" class="py-3 py-lg-0"> {{ $_formatDate(modelObj.end, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY') }} </v-chip>
          </template>
        </v-card-title>
        <v-card-text class="pa-3 pa-lg-5">
          <p class="font-weight-bold body-1 ma-0 text--darken-4"> Are you sure to mark these days as booked? </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="createNewEvent" elevation="2"> yes </v-btn>
          <v-btn color="error" outlined @click="dialog = false"> cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialog: false,
      payload: {
        listOfViews: ['month'],
        loading: false,
        // refreshBtn: !this.xsOnly
        eventLimit: 2
      },
      modelObj: {},
      events: [],
      start: null,
      end: null,
      calendarKey: 1
    }
  },
  components: {
    calendar: () => import('@/components/Calendar.vue')
  },
  mounted () {
    this.$root.$on('calendar', ({ start, end }) => {
      this.start = start.format('YYYY-MM-DDT00:00')
      const endDate = end.format('YYYY-MM-DDTHH:mm')
      this.end = endDate.subtract(1, 'd').format('YYYY-MM-DDT23:59')
      console.log(this.start, this.end)
      // this.getAllEvents(this.start, this.end)
    })
    this.calendarKey++ // forcing calendar component to render
  },
  methods: {
    getAllEvents (start, end) {
      this.$root.$emit('loader', true)
      if (start && end) {
        this.$_execute('post', 'events/filter', { start, end })
          .then(async ({ data }) => {
            await data.map((date) => {
              date.start = this.$_utcToLocal(date.start, 'YYYY-MM-DDTHH:mm')
              date.end = this.$_utcToLocal(date.end, 'YYYY-MM-DDTHH:mm')
              date.title = 'booked'
              date.color = 'green',
              date.textColor = 'white'
            })
            this.events = this.$_copy(data)
          })
          .catch((err) => this.$_handleApiError(err))
          .finally(() => this.$root.$emit('loader', false))
      } else this.$root.$emit('snackbar', { color: 'error', text: 'API error!' })
    },
    select ({ start, end, view }) {
      if (view.name === 'month') end = end.subtract(1, 'day')
      start = this.$_formatDate(start.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'YYYY-MM-DDTHH:mm')
      end = this.$_formatDate(end.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', (view.name === 'month' ? 'YYYY-MM-DDT23:59' : 'YYYY-MM-DDTHH:mm'))
      this.modelObj = this.$_copy({ start, end, view: view.name })
      this.dialog = true
    },
    eventClick ({ _id }) {
      this.$root.$emit('delete', {
        url: 'events',
        _id,
        success: () => {
          this.getAllEvents(this.start, this.end)
        }
      })
    },
    createNewEvent () {
      this.dialog = false
      this.$_execute('post', 'events', this.modelObj)
        .then(() => {
          this.getAllEvents(this.start, this.end)
          this.$root.$emit('snackbar', { color: 'success', text: 'Selected Date(s) marked as booked!.' })
        })
        .catch((err) => this.$_handleApiError(err))
    }
  },
  beforeDestroy () {
    this.$root.$off('calendar')
  }
}
</script>